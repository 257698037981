import React, { useState } from "react";
import { CONTACT_SHAPE } from "../../lib/assets";

export const ContactAreaOne = ({version}) => {
  
  const [formData, setFormData] = useState({
    fullName: '',
    emailAddress: '',
    companyName: '',
    phoneNumber: '',
    projectDetails: '',
  });
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState('');
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const { fullName, emailAddress, companyName, phoneNumber, projectDetails } = formData;
  
    if (
      !fullName ||
      !emailAddress ||
      emailAddress.length <= 5 ||
      !phoneNumber ||
      !projectDetails
    ) {
      alert('Please complete all details.');
      return;
    }
  
    setIsSubmitting(true);
  
    try {
      const response = await fetch('https://hooks.zapier.com/hooks/catch/2303951/28uprsa/', {
        method: 'POST',
        body: JSON.stringify({
          full_name: fullName,
          email_address: emailAddress,
          company_name: companyName,
          phone_number: phoneNumber,
          project_details: projectDetails,
          user_type: 'N/A',
          business_profits: 'N/A',
          platform_version: version,
          site_name: 'Nomadic Tax',
          to_email: 'contact@nomadictax.org',
        }),
      });
  
      if (response.ok) {
        console.log('Contact message sent!');
        setSubmitStatus('Request Submitted!');
        setTimeout(() => {
          setSubmitStatus('');
          setFormData({
            fullName: '',
            emailAddress: '',
            companyName: '',
            phoneNumber: '',
            projectDetails: '',
          });
        }, 2000);
      } else {
        throw new Error('Failed to send message');
      }
    } catch (error) {
      console.error('Submission error:', error);
      alert('There was an error submitting your request. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };
  
  return (
    <section id="contact" className="contact-area contact-bg">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5">
            <div className="contact-content">
              <div className="section-title mb-30 tg-heading-subheading animation-style2">
                <span className="sub-title tg-element-title">GET IN TOUCH</span>
                <h2 className="title tg-element-title">
                  Get a custom quote, for your unique situation.
                </h2>
              </div>
              <p>
                Tell us about your business, or how you generate revenue and we'll get back to you with a bespoke offer to help mitigate tax.
              </p>
            </div>
          </div>

          <div className="col-lg-7">
            <div className="contact-form">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input type="text" required placeholder="Name *" id="form_name" name="fullName" value={formData.fullName} onChange={handleChange}/>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input type="text" placeholder="Company" id="form_company" name="companyName" value={formData.companyName} onChange={handleChange} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input type="email" required placeholder="E-mail *" id="form_email" name="emailAddress" value={formData.emailAddress} onChange={handleChange} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input type="tel" required placeholder="Phone *" id="form_phone" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange}/>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-grp">
                      <textarea placeholder="Details *" required name="projectDetails" value={formData.projectDetails} onChange={handleChange}></textarea>
                    </div>
                  </div>
                </div>
                <button type="submit" id="contact-button" disabled={isSubmitting}>
                  {isSubmitting ? 'Submitting...' : submitStatus || 'Submit Request'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="contact-shape">
        <img src={CONTACT_SHAPE} alt="" />
      </div>
    </section>
  );
};
