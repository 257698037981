import React, { useState } from "react";

export const EstimateForm = ({ type, version }) => {  
  
  const [formData, setFormData] = useState({
    fullName: '',
    emailAddress: '',
    companyName: '',
    phoneNumber: '',
    projectDetails: '',
    businessProfits: '150000'
  });
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState('');
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleRangeChange = (value) => {
    setFormData((prev) => ({ ...prev, businessProfits: value }));
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const { fullName, emailAddress, companyName, phoneNumber, projectDetails, businessProfits } = formData;
  
    if (
      !fullName ||
      !emailAddress ||
      emailAddress.length <= 5 ||
      !phoneNumber
    ) {
      alert('Please complete all details.');
      return;
    }
  
    setIsSubmitting(true);
  
    try {
      const response = await fetch('https://hooks.zapier.com/hooks/catch/2303951/28uprsa/', {
        method: 'POST',
        body: JSON.stringify({
          full_name: fullName,
          email_address: emailAddress,
          company_name: 'N/A',
          phone_number: phoneNumber,
          project_details: 'N/A',
          user_type: type,
          business_profits: businessProfits,
          platform_version: version,
          site_name: 'Nomadic Tax',
          to_email: 'contact@nomadictax.org',
        }),
      });
  
      if (response.ok) {
        console.log('Contact message sent!');
        setSubmitStatus('Request Submitted!');
        setTimeout(() => {
          setSubmitStatus('');
          setFormData({
            fullName: '',
            emailAddress: '',
            companyName: '',
            phoneNumber: '',
            projectDetails: '',
            businessProfits: '150000',
          });
        }, 2000);
      } else {
        throw new Error('Failed to send message');
      }
    } catch (error) {
      console.error('Submission error:', error);
      alert('There was an error submitting your request. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };
  
  const [range, setRange] = useState(150000);

  return (
    <div className="estimate-form-wrap">
      <form onSubmit={handleSubmit}>
        <div className="form-grp">
          <input type="text" placeholder="*Full Name" required id="form_name" name="fullName" value={formData.fullName} onChange={handleChange}/>
        </div>
        <div className="form-grp">
          <input type="email" placeholder="*Email Address" required id="form_email" name="emailAddress" value={formData.emailAddress} onChange={handleChange}/>
        </div>
        <div className="form-grp">
          <input type="number" placeholder="*Phone Number" required id="form_phone" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange}/>
        </div>
        <div className="range-slider-wrap">
          <div className="content-top">
            <p>Business Profits:</p>
            <span>
              $<strong id="rangeValue">{formData.businessProfits}</strong>
            </span>
          </div>
          <input
            className="range"
            type="range"
            value={formData.businessProfits}
            min="65000"
            max="999999"
            step="1000"
            onChange={(e) => handleRangeChange(e.target.value)}
            id="form_profits"
            name="businessProfits"
          />
        </div>

        <button className="btn btn-three" type="submit" id="contact-button" disabled={isSubmitting}>
          {isSubmitting ? 'Calculating...' : submitStatus || 'Calculate Estimate*'}
        </button>
        <br/><br/>
        *Our team will get back to you with an estimate and quotation.
      </form>
    </div>
  );
};
