import React, { useState } from "react";
import { H2_REQUEST_SHAPE01, H2_REQUEST_SHAPE02 } from "../../lib/assets";

export const RequestAreaTwo = ({version}) => {
  
  const [formData, setFormData] = useState({
    fullName: '',
    emailAddress: '',
    phoneNumber: '',
  });
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState('');
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const { fullName, emailAddress, companyName, phoneNumber, projectDetails } = formData;
  
    if (
      !fullName ||
      !emailAddress ||
      emailAddress.length <= 5 ||
      !phoneNumber
    ) {
      alert('Please complete all details.');
      return;
    }
  
    setIsSubmitting(true);
  
    try {
      const response = await fetch('https://hooks.zapier.com/hooks/catch/2303951/28uprsa/', {
        method: 'POST',
        body: JSON.stringify({
          full_name: fullName,
          email_address: emailAddress,
          company_name: 'N/A',
          phone_number: phoneNumber,
          project_details: 'N/A',
          user_type: 'N/A',
          business_profits: 'N/A',
          platform_version: version,
          site_name: 'Nomadic Tax',
          to_email: 'contact@nomadictax.org',
        }),
      });
  
      if (response.ok) {
        console.log('Contact message sent!');
        setSubmitStatus('Request Submitted!');
        setTimeout(() => {
          setSubmitStatus('');
          setFormData({
            fullName: '',
            emailAddress: '',
            companyName: '',
            phoneNumber: '',
            projectDetails: '',
          });
        }, 2000);
      } else {
        throw new Error('Failed to send message');
      }
    } catch (error) {
      console.error('Submission error:', error);
      alert('There was an error submitting your request. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <section className="request-area-two">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="request-content-two">
              <div className="section-title-two white-title mb-15 tg-heading-subheading animation-style3">
                <h2 className="title tg-element-title">Request Discovery Call</h2>
              </div>
              <p>
                Our team will get back to you within 24 hours, to discuss your situation and provide a quotation.
              </p>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="request-form-wrap">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input type="text" required placeholder="Full Name *" id="form_name" name="fullName" value={formData.fullName} onChange={handleChange} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input type="email" required placeholder="E-mail Address *" id="form_email" name="emailAddress" value={formData.emailAddress} onChange={handleChange} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input type="tel" required placeholder="Phone Number *" id="form_phone" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <button type="submit" id="contact-button" disabled={isSubmitting}>
                      {isSubmitting ? 'Requesting...' : submitStatus || 'Request Call'}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="request-shape-wrap">
        <img src={H2_REQUEST_SHAPE01} alt="" />
        <img
          src={H2_REQUEST_SHAPE02}
          alt=""
          data-aos="fade-left"
          data-aos-delay="200"
        />
      </div>
    </section>
  );
};
