import React from "react";
import { Link } from "react-router-dom";
import {
  H4_BANNER_IMG,
  H4_BANNER_SHAPE01,
  H4_BANNER_SHAPE02,
  H4_BANNER_SHAPE03,
  HERO_GRAPHIC,
} from "../../lib/assets";

export const BannerNomads = () => {
  return (
    <section className="banner-area-four banner-bg-four">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-6 col-md-10 order-0 order-lg-2">
            <div className="banner-img-four text-end">
              <img
                src={HERO_GRAPHIC}
                alt=""
                data-aos="fade-left"
                data-aos-delay="400"
              />
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="banner-content-four">
              <span className="sub-title" data-aos="fade-up" data-aos-delay="0">
                Designed for Nomads & Expats 🌍
              </span>
              <h2 className="title" data-aos="fade-up" data-aos-delay="200">
                Tax Optimized <span className="big-word">Receivables</span>
              </h2>
              <p data-aos="fade-up" data-aos-delay="400">
                Nomadic automates and optimizes the receievables process, enabling Nomad and Expat freelancers, creators, entrepreneurs and small businesses to mitigate the tax liability on profits.
              </p>
              <a
                href="https://calendar.app.google/ExQQCXWJPV6s4BYL9"
                target="_blank"
                className="btn btn-three"
                data-aos="fade-up"
                data-aos-delay="600"
              >
                Get Started Now
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="banner-shape-wrap-four">
        <img src={H4_BANNER_SHAPE01} alt="Tax Optimized Invoices" />
        <img
          src={H4_BANNER_SHAPE02}
          alt=""
          data-aos="zoom-in"
          data-aos-delay="600"
        />

      </div>
    </section>
  );
};
